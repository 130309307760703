import React, { useEffect } from "react";
import Product from "../product/Product";
import ProductImage from "../product/ProductImage";
import ProductWide from "../product/ProductWide";
import macbook from "./macbook.png";
import { motion } from "framer-motion";
import "./Home.css";
import { db } from "../firebase";

// useEffect(() => {

//   db.collection("users")
//     .doc(user?.uid)
//     .collection("orders")
//     .orderBy("created", "desc")
//     .onSnapshot((snapshot) => {
//       setOrders(
//         snapshot.docs.map((doc) => ({
//           id: doc.id,
//           data: doc.data(),
//         }))
//       );
//     });
// } else {
//   setOrders([]);
// }

function Home() {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: { duration: 0.1 },
    },
    out: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 0.1 },
    },
  };

  useEffect(async () => {
    var categories = db.collection("categories");
  }, []);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="home"
    >
      <div className="home_container">
        <div className="home_row">
          <ProductImage
            idCategory={"home"}
            id="5w2pOgCQJmkKo7B8gtyJ"
            title="Macbook Pro"
            image="https://i.ibb.co/7pgKNKf/macbook.png"
            rating={5}
            price={429.99}
          />
        </div>

        <div className="home_line">
          <div className="home_products">See Our Products</div>
        </div>

        <div className="home_row">
          <Product
            idCategory={"home"}
            id="M7yaBqX6YXsfHBpTGSeE"
            title="Fitbit Inspire 2 Health & Fitness Tracker with a Free 1-Year Fitbit"
            image="https://images-na.ssl-images-amazon.com/images/I/61yGu14KR8L._AC_SL1500_.jpg"
            rating={5}
            price={128.95}
          />
          <Product
            idCategory={"home"}
            id="KFQSw6yVE8ANiI4OCEeq"
            title="UMIDIGI A7S Unlocked Cell Phones(2GB+32GB), 6.53..."
            image="https://images-na.ssl-images-amazon.com/images/I/71l4WIcaWWL._AC_SL1500_.jpg"
            rating={5}
            price={159.99}
          />
          <Product
            idCategory={"home"}
            id="C7xsz2J19QaNRAywSVxK"
            title="The lean startup"
            image="https://images-na.ssl-images-amazon.com/images/I/51Zymoq7UnL._AC_SY400_.jpg"
            rating={5}
            price={19.99}
          />
        </div>

        <div className="home_row">
          <ProductWide
            idCategory={"home"}
            id="pfrLB32bswYCvJJdF4Cy"
            title="Samsung Home Entertainment LC49G95TSSNXZA 240Hz G-Sync WQHD"
            image="https://images-na.ssl-images-amazon.com/images/I/61SQz8S%2BfEL._AC_SL1000_.jpg"
            rating={4}
            price={1999.99}
          />
        </div>
      </div>
    </motion.div>
  );
}
export default Home;

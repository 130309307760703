import React from "react";
import "./Order.css";
import moment from "moment";
import CheckoutProduct from "./CheckoutProduct";
import currencyFormatter from "currency-formatter";

const Order = ({ order }) => {
  let total = order.data.amount / 100;

  return (
    <div className="order">
      <div className="order_heading">
        <div className="order_option">
          <h3>Order Placed</h3>
          <p>{moment.unix(order.data.created).format("MMMM Do YYYY")}</p>
        </div>

        <div className="order_option">
          <h3>Total</h3>
          <p> {currencyFormatter.format(total, { code: "USD" })}</p>
        </div>

        <div className="order_option">
          <div className="order_number">
            <h3>Order #</h3>
            <p className="order_id">{order.id}</p>
          </div>
          <div>
            <p className="order_details">order details</p>
          </div>
        </div>
      </div>
      {order.data.basket?.map((item) => (
        <CheckoutProduct
          id={item.id}
          title={item.title}
          image={item.image}
          price={item.price}
          rating={item.rating}
          hideButton={true}
        />
      ))}
    </div>
  );
};

export default Order;

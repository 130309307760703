import React from "react";
import { motion } from "framer-motion";
import "./ExpressMessage.css";

const ExpressMessage = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: { duration: 0.1 },
    },
    out: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 0.1 },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="expressmessage"
    >
      <div className="expressmessage_text">
        FREE EXPRESS DELIVERY OVER $50* FOR A LIMITED TIME ONLY
      </div>
    </motion.div>
  );
};

export default ExpressMessage;

import React, { useEffect, useState } from "react";
import Product from "../product/Product";
import "./Category.css";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { motion } from "framer-motion";
import { ContactSupportOutlined } from "@material-ui/icons";

function Category() {
  const [categoryData, setCategoryData] = useState([]);
  const [page, setPage] = useState(0);

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: { duration: 0.1 },
    },
    out: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 0.1 },
    },
  };

  let { id } = useParams();
  id = id.replace(/-/g, "");
  let categoryID = id;

  let categories = [
    "pcparts",
    "systems",
    "audiovisual",
    "peripherals",
    "hardware",
    "accessories",
  ];
  let pcparts = ["cases", "cooling", "cpu", "graphiccards", "harddrives"];
  let systems = [
    "gamingpc",
    "homeandoffice",
    "servers",
    "workstationandcreative",
    "workstationcustom",
  ];
  let peripherals = [
    "digitaleyeware",
    "joysticksandgamepads",
    "pedalsandshifters",
    "steeringwheel",
    "streaming",
    "VR",
  ];
  let hardware = [
    "laptopsandnotebooks",
    "mobilephones",
    "poweradapters",
    "printers",
    "scanners",
    "stands",
    "tablets",
  ];
  let audiovisual = [
    "mediaplayers",
    "remotes",
    "soundbars",
    "soundsystem",
    "speakers",
    "webcameras",
  ];
  let accessories = [
    "airpurifiers",
    "cablesandadapters",
    "cleaning",
    "ledlighting",
    "toolkits",
  ];

  useEffect(async () => {
    if (categories.includes(id))
      db.collection("categories")
        .doc(id)
        .collection(id)
        .onSnapshot((snapshot) => {
          setCategoryData(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        });
    else {
      let productCategory;
      if (pcparts.includes(id)) productCategory = "pcparts";
      else if (systems.includes(id)) productCategory = "systems";
      else if (peripherals.includes(id)) productCategory = "peripherals";
      else if (hardware.includes(id)) productCategory = "hardware";
      else if (audiovisual.includes(id)) productCategory = "audiovisual";
      else if (accessories.includes(id)) productCategory = "accessories";

      db.collection("products")
        .doc(productCategory)
        .collection(id)
        .onSnapshot((snapshot) => {
          setCategoryData(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        });
    }
  }, [id]);

  let idCategory;
  if (id === "pcparts") idCategory = "PC Parts";
  else if (id === "audiovisual") idCategory = "Audio Visual";
  else if (id === "graphiccards") idCategory = "Graphic Cards";
  else if (id === "gamingpc") idCategory = "Gaming PC";
  else if (id === "homeandoffice") idCategory = "Home & Office";
  else if (id === "workstationandcreative")
    idCategory = "Workstation & Creative";
  else if (id === "workstationcustom") idCategory = "Custom System";
  else if (id === "soundsystem") idCategory = "Sound System";
  else if (id === "webcameras") idCategory = "Web Cameras";
  else if (id === "mediaplayers") idCategory = "Media Players";
  else if (id === "steeringwheel") idCategory = "Steering Wheel";
  else if (id === "pedalsandshifters") idCategory = "Pedals & Shifters";
  else if (id === "joysticksandgamepads") idCategory = "Joysticks & Gamepads";
  else if (id === "laptopsandnotebooks") idCategory = "Laptops & Notebooks";
  else if (id === "digitaleyeware") idCategory = "Digital Eyeware";
  else if (id === "poweradapters") idCategory = "Power Adapters";
  else if (id === "mobilephones") idCategory = "Mobile Phones";
  else if (id === "cablesandadapters") idCategory = "Cables & Adapters";
  else if (id === "airpurifiers") idCategory = "Air Purifiers";
  else if (id === "ledlighting") idCategory = "LED Lighting";
  else {
    idCategory = id.charAt(0).toUpperCase() + id.slice(1);
  }

  let row = [];
  let products = [];

  let datlegnth = Math.floor((categoryData.length + 1) / 9);

  categoryData.every(({ id, data }, index) => {
    if (index >= page * 9 && index <= 8 + page * 9) {
      products.push(
        <Product
          idCategory={categoryID}
          id={id}
          title={data.title}
          image={data.image}
          rating={data.rating}
          price={data.price}
        />
      );

      if ((index + 1) % 3 == 0) {
        products.push(<div className="pcparts_row">{row}</div>);
        row = [];
      }
      return true;
    } else if (index > page * 9 + 8) return false;
    else return true;
  });

  const nextPage = () => {
    let asd =
      page === Math.floor((categoryData.length + 1) / 9)
        ? ""
        : setPage(page + 1);
    scrollTop();
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const prevPage = () => {
    scrollTop();
    let asda = page > 0 ? setPage(page - 1) : "";
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="pcparts"
    >
      <div className="pcparts_container">
        <div className="pcparts_line">
          <div className="pcparts_products_category">{idCategory}</div>
        </div>
        {products.length === 0 ? (
          <div className="category_lds_container">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div>
            <div className="pcparts_products">{products}</div>
            {categoryData.length > 9 && (
              <div className="pcparts_page">
                <p onClick={() => prevPage()}>Previous Page</p>
                <p onClick={() => nextPage()}>Next Page</p>
              </div>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
}
export default Category;

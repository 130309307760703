import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZS_qdwZisOirTQCwtXkq92N2xdZVLOOU",
  authDomain: "c-c3021.firebaseapp.com",
  projectId: "c-c3021",
  storageBucket: "c-c3021.appspot.com",
  messagingSenderId: "600556137789",
  appId: "1:600556137789:web:c119c21ef29ebd5492a954",
  measurementId: "G-D9Y7L2DDLK",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const firestore = firebase.firestore();

export { db, auth, firestore };

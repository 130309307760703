import React, { useState } from "react";
import "./CheckoutProduct.css";
import { useStateValue } from "./StateProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

toast.configure({
  autoClose: 1500,
  pauseOnFocusLoss: false,
});
function CheckoutProduct({ id, image, title, price, rating, hideButton }) {
  const [{ basket }, dispatch] = useStateValue();

  const notify = () => {
    toast("Removed from Basket");
  };

  const notifyAdd = () => {
    toast("Added to Basket");
  };

  const removeFromBasket = () => {
    notify();

    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    });
  };

  const returnProduct = () => {
    toast("Your return request has been sent");
  };

  const buyAgain = () => {

    notifyAdd();

    dispatch({
      type: "ADD_TO_BASKET",
      id: id,
    });
  };


  let history = useHistory();

  return (
    <div className="checkoutProduct">
      <img className="checkoutProduct_image" src={image} />
      <div className="checkoutProduct_info">
        <div>
          <div className="checkoutProduct_title">{title}</div>

          <p className="checkoutProduct_price">
            <small>$</small>
            <>{price}</>
          </p>
        </div>

        <div className="checkoutProduct_rating"></div>
        {!hideButton && (
          <button onClick={removeFromBasket}>Remove from Basket</button>
        )}
        {hideButton && (
          <div className="checkoutProduct_button">
            {/* <button onClick={buyAgain}>Buy it again</button> */}
            <button onClick={returnProduct}>Return</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CheckoutProduct;

import React from "react";
import "./Checkout.css";
import CheckoutProduct from "./CheckoutProduct";
import { useStateValue } from "./StateProvider.js";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import Subtotal from "./Subtotal";

function Checkout() {
  const [{ basket, user }, dispatch] = useStateValue();
  let history = useHistory();

  if (user === null) {
    history.push("/login");
  }

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: { duration: 0.1 },
    },
    out: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 0.1 },
    },
  };

  let name = user?.email.split("@")[0];
  if (name !== undefined) name = name[0].toUpperCase() + name.slice(1);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="checkout"
    >
      <div className="checkout_left">
        {/* <img src="https://cdn.vox-cdn.com/thumbor/xsjr9vp-elYcUjGUB6b60EQzQJU=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/7591519/amazon_go_stock_1.jpg" className="checkout_ad" /> */}

        <div className="checkout_hello">
          <h3>Hello {name},</h3>
          <h2 className="checkout_title">Your shopping basket</h2>

          {basket.map((item) => (
            <CheckoutProduct
              // idCategory={}
              id={item.id}
              title={item.title}
              image={item.image}
              price={item.price}
              rating={item.rating}
            />
          ))}
        </div>
      </div>

      <div className="checkout_right">
        <Subtotal />
      </div>
    </motion.div>
  );
}

export default Checkout;

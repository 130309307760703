import React from "react";
import { useHistory } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  let history = useHistory();

  const historyLink = (link) => {
    history.push(`/${link}`);
  };

  return (
    <div className="footer">
      {/* logo */}
      <div className="footer_logo" onClick={() => historyLink("")}>
        <p className="footer_logoText">scorptec</p>

        <div className="footer_logoDot">
          <p></p>
        </div>
      </div>

      <div className="footer_categories">
        <div className="footer_category">
          <div
            className="footer_categoryText"
            onClick={() => historyLink("pcparts")}
          >
            PC parts
          </div>
          <p onClick={() => historyLink("cases")}>cases</p>
          <p onClick={() => historyLink("cpu")}>CPU</p>
          <p onClick={() => historyLink("cooling")}>cooling</p>
          <p onClick={() => historyLink("harddrives")}>hard drives</p>
          <p onClick={() => historyLink("graphiccards")}>Graphics cards</p>
        </div>
        <div className="footer_category">
          <div
            className="footer_categoryText"
            onClick={() => historyLink("systems")}
          >
            systems
          </div>
          <p onClick={() => historyLink("gamingpc")}>gaming PC</p>
          <p onClick={() => historyLink("homeandoffice")}>Home & Office</p>
          <p onClick={() => historyLink("servers")}>Servers</p>
          <p onClick={() => historyLink("workstationandcreative")}>
            workstation & creative
          </p>
          <p onClick={() => historyLink("workstationcustom")}>custom system</p>
        </div>
        <div className="footer_category">
          <div
            className="footer_categoryText"
            onClick={() => historyLink("audiovisual")}
          >
            audio visual
          </div>
          <p onClick={() => historyLink("speakers")}>speakers</p>
          <p onClick={() => historyLink("soundbars")}> soundbars</p>
          <p onClick={() => historyLink("soundsystem")}>sound sytems</p>
          <p onClick={() => historyLink("webcameras")}>web cameras</p>
          <p onClick={() => historyLink("mediaplayers")}>media players</p>
          <p onClick={() => historyLink("remotes")}>remotes</p>
        </div>
        <div className="footer_category">
          <div
            className="footer_categoryText"
            onClick={() => historyLink("peripherals")}
          >
            peripherals
          </div>
          <p onClick={() => historyLink("streaming")}>Streaming</p>
          <p onClick={() => historyLink("steeringwheel")}>Steering wheels</p>
          <p onClick={() => historyLink("pedalsandshifters")}>
            pedals & shifters
          </p>
          <p onClick={() => historyLink("joysticksandgamepads")}>
            joystics & gamepads
          </p>
          <p onClick={() => historyLink("VR")}>VR</p>
          <p onClick={() => historyLink("digitaleyeware")}>digital eyeware</p>
        </div>
        <div className="footer_category">
          <div
            className="footer_categoryText"
            onClick={() => historyLink("hardware")}
          >
            hardware
          </div>
          <p onClick={() => historyLink("laptopsandnotebooks")}>
            laptops & notebooks
          </p>
          <p onClick={() => historyLink("tablets")}>tablets</p>
          <p onClick={() => historyLink("poweradapters")}>power adapters</p>
          <p onClick={() => historyLink("stands")}>stands</p>
          <p onClick={() => historyLink("mobilephones")}>mobile phones</p>
          <p onClick={() => historyLink("printers")}>printers</p>
          <p onClick={() => historyLink("scanners")}>scanners</p>
        </div>
        <div className="footer_category">
          <div
            className="footer_categoryText"
            onClick={() => historyLink("accessories")}
          >
            accessories
          </div>
          <p onClick={() => historyLink("cablesandadapters")}>
            cables & adapters
          </p>
          <p onClick={() => historyLink("airpurifiers")}>air purifiers</p>
          <p onClick={() => historyLink("ledlighting")}>LED lighting</p>
          <p onClick={() => historyLink("cleaning")}>cleaning</p>
          <p onClick={() => historyLink("toolkits")}>toolkits</p>
        </div>
      </div>

      <div className="footer_link">
        <p onClick={() => historyLink("warranty")}>warranty</p>
        <p onClick={() => historyLink("jobs")}>jobs</p>
        <p onClick={() => historyLink("aboutus")}>about us</p>
        <p onClick={() => historyLink("contactus")}>contact us</p>
      </div>

      <div className="footer_border"></div>

      <div className="footer_copyright">
        <div>
          Copyright &copy; 1998-2021. Scorptec Computers. ABN #797720896. All
          Rights Reserved.
        </div>

        <div className="footer_terms">
          <div onClick={() => historyLink("termsandconditions")}>
            Terms & Conditions
          </div>
          <div onClick={() => historyLink("privacystatement")}>
            Privacy Statement
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import "./Subtotal.css";
import { useStateValue } from "./StateProvider.js";
import { getBasketTotal } from "./reducer";
import { useHistory, withRouter } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";

toast.configure({
  autoClose: 1500,
  pauseOnFocusLoss: false,
});
function Subtotal() {
  const history = useHistory();
  const [{ basket }, dispatch] = useStateValue();
  const notify = () => {
    toast("No Items");
  };

  return (
    <div className="subtotal">
      <>
        <p className="subtotal_total">
          {/* Part of the homework */}
          Subtotal ({basket?.length} items):{" "}
          <strong>
            {currencyFormatter.format(getBasketTotal(basket), { code: "USD" })}
          </strong>
        </p>
        <small className="subtotal_gift">
          <input type="checkbox" />
          This order contains a gift
        </small>
      </>

      <button
        onClick={() => {
          if (basket.length > 0) {
            history.push("/payment");
          } else {
            notify();
          }
        }}
      >
        Proceed to Checkout
      </button>
    </div>
  );
}

export default withRouter(Subtotal);

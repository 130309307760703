import React, { useState } from "react";
import "./Navigation.css";
import classNames from "classnames";
import searchIcon from "./search-icon.png";
import { Link, withRouter } from "react-router-dom";

const Navigation = (props) => {
  const [search, setSearch] = useState("");

  return (
    <div className="navigation">
      <div className="navigation_option">
        <Link to="/" className="navigation_link">
          <div className="navigation_logo">
            <p className="navigation_logoText">scorptec</p>

            <div className="navigation_logoDot">
              <p></p>
            </div>
          </div>
        </Link>

        <div>
          {/* Menu */}
          <div className="navigation_menu">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

      <div className="navigation_category">
        <Link
          to="/pcparts"
          className={classNames("navigation_categoryText", {
            navigation_categoryTextSelect:
              props.history.location.pathname === "/pcparts",
          })}
        >
          PC Parts
        </Link>

        <Link
          to="/systems"
          className={classNames("navigation_categoryText", {
            navigation_categoryTextSelect:
              props.history.location.pathname === "/systems",
          })}
        >
          Systems
        </Link>

        <Link
          to="/audio-visual"
          className={classNames("navigation_categoryText", {
            navigation_categoryTextSelect:
              props.history.location.pathname === "/audio-visual",
          })}
        >
          Audio Visual
        </Link>

        <Link
          to="/peripherals"
          className={classNames("navigation_categoryText", {
            navigation_categoryTextSelect:
              props.history.location.pathname === "/peripherals",
          })}
        >
          Peripherals
        </Link>

        <Link
          to="/hardware"
          className={classNames("navigation_categoryText", {
            navigation_categoryTextSelect:
              props.history.location.pathname === "/hardware",
          })}
        >
          Hardware
        </Link>

        <Link
          to="/accessories"
          className={classNames("navigation_categoryText", {
            navigation_categoryTextSelect:
              props.history.location.pathname === "/accessories",
          })}
        >
          Accessories
        </Link>
        <Link
          to="/corporate"
          className={classNames("navigation_categoryText", {
            navigation_categoryTextSelect:
              props.history.location.pathname === "/corporate",
          })}
        >
          Corporate
        </Link>
      </div>

      <div className="navigation_search">
        <img src={searchIcon} className="navigation_searchIcon" />
        <input
          className="navigation_searchInput"
          type="text"
          placeholder="search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(Navigation);

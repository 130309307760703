import React, { useState } from "react";
import { db } from "./firebase";
import { v4 as uuidv4 } from "uuid";

//was doing laotpand notebooks do add
import workstationcustom from "./productsjson/workstationcustom.json";

const AddProduct = () => {
  const addProducts = () => {
    workstationcustom.results.map((product) => {
      let productID = uuidv4();
      db.collection("products")
        .doc("systems")
        .collection("workstationcustom")
        .add({
          id: productID,
          image: product.image,
          title: product.title,
          price: product.prices.current_price,
          rating: product.reviews.stars,
        });
    });
  };

  return (
    <div>
      <button onClick={addProducts}>Add Product</button>
    </div>
  );
};

export default AddProduct;

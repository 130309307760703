import React, { useEffect } from "react";
import "./App.css";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Home from "./home/Home";
import Navigation from "./navigation/Navigation";
import Login from "./Login";
import Payment from "./Payment";
import Orders from "./Orders";
import { AnimatePresence } from "framer-motion";
import Checkout from "./Checkout";
import { Switch, Route, useLocation } from "react-router-dom";
import { auth } from "./firebase";
import { useStateValue } from "./StateProvider";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Corporate from "./Corporate";
import ProductID from "./productID/ProductID";
import AddProduct from "./AddProduct";
import ExpressMessage from "./expressMessage/ExpressMessage";
import Category from "./categories/Category";
import Warranty from "./warranty/Warranty";
import Jobs from "./jobs/Jobs";
import About from "./about/About";
import Contact from "./contact/Contact";
import ScrollToTop from "./ScrollToTop";
import Privacy from "./privacy/Privacy";
import Terms from "./terms/Terms";

function App() {
  const promise = loadStripe(
    "pk_test_51IzreGKDk4TJzn7tPHWdNf82ImhXrrufLLZp0umC1yvwZrFeKzuWzFueRcxsqKIQmmTxlgUVWSbksOSGjsFwHElv00ldaz4S14"
  );

  const location = useLocation();

  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    //will only run when component loads

    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // user just logged in

        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        // user logged out

        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });
  }, []);

  return (
    <>
      <div className="app">
        <AnimatePresence exitBeforeEnter>
          <ScrollToTop />
          <Switch location={location} key={location.key}>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/orders">
              <Header />
              <Navigation />
              <Orders />
              <Footer />
            </Route>
            <Route path="/checkout">
              <Header />
              <Navigation />
              <Checkout />
              <Footer />
            </Route>
            <Route path="/payment">
              <Header />
              <Navigation />
              <Elements stripe={promise}>
                <Payment />
              </Elements>
              <Footer />
            </Route>
            <Route path="/corporate">
              <Header />
              <Navigation />
              <Corporate />
              <Footer />
            </Route>

            <Route path="/add">
              <AddProduct />
            </Route>

            <Route path="/merchanise">
              <AddProduct />
            </Route>
            <Route path="/warranty">
              <Header />
              <Navigation />
              <Warranty />
              <Footer />
            </Route>
            <Route path="/jobs">
              <Header />
              <Navigation />
              <Jobs />
              <Footer />
            </Route>
            <Route path="/aboutus">
              <Header />
              <Navigation />
              <About />
              <Footer />
            </Route>
            <Route path="/contactus">
              <Header />
              <Navigation />
              <Contact />
              <Footer />
            </Route>
            <Route path="/termsandconditions">
              <Header />
              <Navigation />
              <Terms />
              <Footer />
            </Route>
            <Route path="/privacystatement">
              <Header />
              <Navigation />
              <Privacy />
              <Footer />
            </Route>

            <Route path="/product/:id">
              <Header />
              <Navigation />
              <ProductID />
              <Footer />
            </Route>

            <Route path="/:id">
              <Header />
              <Navigation />
              <Category />
              <Footer />
            </Route>

            <Route path="/">
              <Header />
              <Navigation />
              <ExpressMessage />
              <Home />
              <Footer />
            </Route>
          </Switch>
        </AnimatePresence>
      </div>
    </>
  );
}

export default App;

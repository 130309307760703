import React from "react";
import { useStateValue } from "../StateProvider";
import "./ProductImage.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import { useHistory } from "react-router-dom";

toast.configure({
  autoClose: 1500,
  pauseOnFocusLoss: false,
});
function ProductImage({ idCategory, id, title, image, desc, rating, price }) {
  const [state, dispatch] = useStateValue();

  const notify = () => {
    toast("Added to Basket");
  };

  const addToBasket = () => {
    notify();

    // dispatch item into basket
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: id,
        title: title,
        image: image,
        price: price,
        rating: rating,
      },
    });
  };

  let history = useHistory();

  return (
    <div
      className="productImage"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        width: "100%",
        height: "350px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="productImage_absolute">
        <div className="productImage_info">
          <p
            className="productImage_sale productImage_link"
            onClick={() => history.push(`/product/${id}`)}
          >
            On sale
          </p>
          <p
            className="productImage_title productImage_link"
            onClick={() => history.push(`/product/${idCategory}/${id}`)}
          >
            {title}
          </p>
          <div
            className="productImage_desc productImage_link"
            onClick={() => history.push(`/product/${idCategory}/${id}`)}
          >
            <p className="productImage_description">
              The ultimate pro. 16 inch
            </p>
            <p className="productImage_description">model</p>
          </div>

          <div className="productImage_shop">
            <p
              className="productImage_price productImage_link"
              onClick={() => history.push(`/product/${idCategory}/${id}`)}
            >
              <small>$</small>
              {price}
            </p>
            <button onClick={addToBasket} className="productImage_button">
              Add to Basket
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductImage;

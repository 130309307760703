import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about">
      <div className="about_container">
        <div className="about_option">About Us</div>
        <div className="about_optionLineOne">
          Scorptec Computers is a strong, relationship-focused North American
          business. We have stores in North America and Europe.
        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import "./Privacy.css";

const About = () => {
  return (
    <div className="privacy">
      <div className="privacy_container">
        <div className="privacy_option">Privacy Statement</div>
        <div className="privacy_optionLineOne">
          The purpose of this Privacy Statement is to inform you about the types
          of personal information Kellogg Canada Inc. (as follows, “Kellogg”,
          “we” or “us”) collects, uses and discloses. It explains how we use and
          disclose that information, the choices you have regarding such use and
          disclosure and how you may correct that information. We are proud to
          demonstrate our commitment to your privacy by complying with the laws
          and regulations under applicable privacy laws in Canada, including the
          Personal Information Protection and Electronic Documents Act. We will
          treat personal information in a manner consistent with the version of
          the Privacy Statement under which it was collected and our Privacy
          Policy, unless we have your consent to treat it differently.
        </div>
      </div>
    </div>
  );
};

export default About;

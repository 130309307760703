import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Contact.css";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const sentEmail = (e) => {
    e.preventDefault();

    setEmail("");
    setSubject("");
    setMessage("");

    setEmailSent(true);
    setTimeout(() => {
      setEmailSent(false);
    }, 1000);
  };

  return (
    <div className="contact">
      <div className="contact_optionLineOne">Contact Us</div>
      <div className="contact_option">
        <form onSubmit={(e) => sentEmail(e)}>
          <div className="contact_line">
            <p>Email</p>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
            />
          </div>
          <div className="contact_line">
            <p>Subject</p>
            <input
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="contact_line">
            <p>Message</p>
            <textarea
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="4"
              cols="50"
            />
          </div>
          <button type="submit" className="contact_submit">
            Submit
          </button>
        </form>
        <div
          style={{
            height: "100px",
          }}
        >
          <AnimatePresence>
            {emailSent && (
              <motion.div
                initial={{
                  opacity: 0,
                  x: -100,
                  transition: {
                    type: "spring",
                    bounce: 0.1,
                  },
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    type: "spring",
                    bounce: 0.1,
                  },
                }}
                exit={{
                  opacity: 0,
                  x: 100,
                  transition: {
                    type: "spring",
                    bounce: 0.1,
                  },
                }}
              >
                <div className="contact_sent">Email Sent</div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import "./Jobs.css";

const Jobs = () => {
  return (
    <div className="jobs">
      <div className="jobs_container">
        <div className="jobs_option">SCORPTEC CAREERS</div>
        <div className="jobs_optionLineOne">
          We are building a strong, relationship-focused North American
          business.
        </div>
        <div className="jobs_optionLineOne">
          Are you ready to help us get there?
        </div>
        <div className="jobs_optionLineOne">
          Let’s build the future of scorptec together.
        </div>

        <div className="jobs_careers">
          <p>Are you ready to help build the future of banking?</p>
          <div>Find your opportunity</div>
        </div>
      </div>
    </div>
  );
};

export default Jobs;

import React from "react";

const About = () => {
  return (
    <div className="about">
      <div className="about_container">
        <div className="about_option">Terms and Conditions</div>
        <div className="about_optionLineOne">
          <p>Last updated: June 14, 2021</p>
          <p>
            Please read these terms and conditions carefully before using Our
            Service.
          </p>
          <p>Interpretation and Definitions</p>
          <p>Interpretation</p>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <p>Definitions</p>
          <p>For the purposes of these Terms and Conditions:</p>

          <p>
            Affiliate means an entity that controls, is controlled by or is
            under common control with a party, where &quot;control&quot; means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </p>

          <p>Country refers to: Ontario, Canada</p>

          <p>
            Company (referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to scorptec, 1 Scorptec, CA 12616.
          </p>

          <p>
            Device means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useState } from "react";
import "./Login.css";
import { auth } from "./firebase";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signin = (e) => {
    e.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        history.push("/");
      })
      .catch((err) => alert(err.message));

    // FIRE LOGIN HERE
  };

  const register = (e) => {
    e.preventDefault();

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        // successfully carted new user

        if (auth) history.push("/");
      })
      .catch((error) => alert(error));
  };

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: { duration: 0.1 },
    },
    out: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 0.1 },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="login"
    >
      <Link to="/" className="login_logo">
        <p className="login_logoText">scorptec</p>

        <div className="login_logoDot">
          <p></p>
        </div>
      </Link>

      <div className="login_container">
        <h1>Sign-in</h1>

        <form>
          <h5>Email</h5>
          <input
            className="login_input"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <h5>Password</h5>
          <input
            className="login_input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button onClick={(e) => signin(e)} className="login_signInButton">
            Sign In
          </button>
        </form>

        <p>
          By signing-in you agree to the scorptec conditions of use & sale.
          Plesae see our Privacy Notice and our Cookies Notice.{" "}
        </p>
        <button onClick={(e) => register(e)} className="login_registerButton">
          Create your Amazon Account
        </button>
      </div>
    </motion.div>
  );
}

export default withRouter(Login);

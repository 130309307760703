import React from "react";
import { ReactComponent as ClaimsLogo } from "./claims.svg";
import { ReactComponent as FileclaimLogo } from "./fileclaim.svg";
import { ReactComponent as ReceiptLogo } from "./receipt.svg";
import { ReactComponent as RepairLogo } from "./repair.svg";
import "./Warranty.css";

const Warraty = () => {
  return (
    <div className="warranty">
      <div className="warranty_container">
        <div className="warranty_title">Warranty</div>

        <div className="warranty_optionLineOne">
          Our Protection Plans have you covered.
        </div>
        <div className="warranty_plans">
          <p>Drops & Cracked Screens (accident plans only)</p>
          <p>Spills & Liquid Damage (accident plans only)</p>
          <p>Mechanical & Electrical Failures</p>
          <p>Speaker/Sound Failure</p>
          <p>Screen Failure</p>
          <p>Won't Power On </p>
          <p>Power Supply Burnout</p>
          <p>Remote Failure</p>
        </div>
        <div className="warranty_optionLineOne">
          We get you back up and running, fast.
        </div>

        <div className="warranty_file">
          <div className="warranty_option">
            <ClaimsLogo className="warranty_logo" />
            <p>File a claim online at scorptec.com/claim anytime, 24/7.</p>
          </div>
          <div className="warranty_option">
            <FileclaimLogo className="warranty_logo" />
            <p>Have your receipt and covered item with you.</p>
          </div>
          <div className="warranty_option">
            <ReceiptLogo className="warranty_logo" />
            <p>
              Many claims are approved instantly. In other cases, a specialist
              will guide you through next steps.
            </p>
          </div>
          <div className="warranty_option">
            <RepairLogo className="warranty_logo" />
            <p>
              We’ll repair your item. If we can’t repair it, we’ll send you a
              replacement or reimburse you for one.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warraty;

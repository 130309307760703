import React from "react";
import "./Header.css";
import person from "./person.png";
import dollar from "./dollar.png";
import sweden from "./sweden.png";
import shoppingBag from "./shopping-bag.png";
import { Link } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import { ReactComponent as MailLogo } from "./mail.svg";
import { ReactComponent as ReturnsLogo } from "./returns.svg";
import { useStateValue } from "../StateProvider.js";
import { auth } from "../firebase";
import { getBasketTotal } from "../reducer";

const Header = () => {
  const [{ basket, user }, dispatch] = useStateValue();

  const handleAuthentication = () => {
    if (user) {
      auth.signOut();
    }
  };

  return (
    <div className="header">
      <div className="header_option">
        <div className="header_mail">
          <MailLogo className="header_mailLogo" />
          <div className="header_number">02 9834 4577</div>
        </div>
        <div className="header_language">
          <img src={sweden} className="header_languageLogo" />
          <div className="header_languageText">English</div>
        </div>
        <div className="header_language">
          <img src={dollar} className="header_dollarLogo" />
        </div>
      </div>

      <div className="header_option">
        {!user && (
          <Link to={!user && "/login"} className="header_link">
            <div className="header_account">
              <img className="header_accountLogo" src={person} />
              <div className="header_accountText">
                {user ? <div>Danish Anwer Sign Out</div> : "Account"}
              </div>
            </div>
          </Link>
        )}

        {user && (
          <div className="header_account">
            <img className="header_accountLogo" src={person} />
            <div className="header_accountText">
              <div style={{ marginRight: "8px", color: "black" }}>
                Danish Anwer
              </div>
              <div
                className="header_link"
                style={{ fontSize: "13px" }}
                onClick={handleAuthentication}
              >
                Sign Out
              </div>
            </div>
          </div>
        )}

        <Link to="/orders" className="header_link header_returns">
          <ReturnsLogo className="header_returnsLogo" />
          <div className="header_returnsText">
            <div style={{ marginRight: "8px" }}>Returns</div>
          </div>
        </Link>

        <Link to="/checkout" className="header_link">
          <div className="header_basket">
            <img className="header_basketLogo" src={shoppingBag} />
            <div className="header_basketText">
              {currencyFormatter.format(getBasketTotal(basket), {
                code: "USD",
              })}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Header;

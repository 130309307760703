import React from "react";
import { useStateValue } from "../StateProvider";
import "./ProductWide.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

toast.configure({
  autoClose: 1500,
  pauseOnFocusLoss: false,
});

function Product({ idCategory, id, title, image, rating, price }) {
  const [state, dispatch] = useStateValue();

  const notify = () => {
    toast("Added to Basket");
  };

  const addToBasket = () => {
    notify();

    // dispatch item into basket
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: id,
        title: title,
        image: image,
        price: price,
        rating: rating,
      },
    });
  };

  let history = useHistory();

  return (
    <div className="productWide">
      <div
        className="product_info product_link"
        onClick={() => history.push(`/product/${idCategory}/${id}`)}
      >
        <p className="product_title">{title}</p>
        <p className="product_price">
          <small>$</small>
          <strong>{price}</strong>
        </p>
      </div>
      <img
        src={image}
        className="product_link productWide_image"
        onClick={() => history.push(`/product/${idCategory}/${id}`)}
      />
      <div className="product_button">
        <div className="border"></div>
        <button onClick={addToBasket}>Add to Basket</button>
      </div>
    </div>
  );
}

export default Product;

import React from "react";
import "./Corporate.css";
import { Link } from "react-router-dom";

const Corporate = () => {
  return (
    <div className="corporate">
      <div className="corporate_container">
        <div className="corporate_optionLineOne">Oh, Hey</div>
        <div className="corporate_optionLineTwo">
          Welcome to Scorptec. It's great to meet you!
        </div>

        <div className="corporate_optionParaOne">
          We promise to keep you up-to-date with the best computer equipment,
          tell you about our awesome sales and throw in the odd surpruse and
          special offer.
        </div>

        <div className="corporate_optionParaTwo">
          And with around 850 brands to browse, we're sure there's something to
          suit your style.
        </div>
        <div className="corporate_optionLineThree">
          Come see for yourself...
        </div>

        <Link to='/' className="corporate_link">
          <button>Explore scorptec</button>
        </Link>
      </div>
    </div>
  );
};

export default Corporate;

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./ProductID.css";
import { useStateValue } from "../StateProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as BackArrow } from "./back-arrow.svg";

import { db } from "../firebase";

toast.configure({
  autoClose: 1500,
  pauseOnFocusLoss: false,
});

const ProductID = () => {
  const [state, dispatch] = useStateValue();

  const [product, setProduct] = useState({
    image: "",
    price: null,
    id: "",
    image: "",
    rating: null,
    title: "",
  });

  const location = useLocation();
  const history = useHistory();

  let id = location.pathname.split("/");
  let categoryId = id[2];
  let productId = id[3];

  let categories = [
    "pcparts",
    "systems",
    "audiovisual",
    "peripherals",
    "hardware",
    "accessories",
    "home",
  ];
  let pcparts = ["cases", "cooling", "cpu", "graphiccards", "harddrives"];
  let systems = [
    "gamingpc",
    "homeandoffice",
    "servers",
    "workstationandcreative",
    "workstationcustom",
  ];
  let peripherals = [
    "digitaleyeware",
    "joysticksandgamepads",
    "pedalsandshifters",
    "steeringwheel",
    "streaming",
    "VR",
  ];
  let hardware = [
    "laptopsandnotebooks",
    "mobilephones",
    "poweradapters",
    "printers",
    "scanners",
    "stands",
    "tablets",
  ];
  let audiovisual = [
    "mediaplayers",
    "remotes",
    "soundbars",
    "soundsystem",
    "speakers",
    "webcameras",
  ];
  let accessories = [
    "airpurifiers",
    "cablesandadapters",
    "cleaning",
    "ledlighting",
    "toolkits",
  ];

  let collection, productCategory;

  if (categories.includes(categoryId)) {
    productCategory = categoryId;
    collection = "categories";
  } else if (pcparts.includes(categoryId)) {
    productCategory = "pcparts";
    collection = "products";
  } else if (systems.includes(categoryId)) {
    productCategory = "systems";
    collection = "products";
  } else if (peripherals.includes(categoryId)) {
    productCategory = "peripherals";
    collection = "products";
  } else if (hardware.includes(categoryId)) {
    productCategory = "hardware";
    collection = "products";
  } else if (audiovisual.includes(categoryId)) {
    productCategory = "audiovisual";
    collection = "products";
  } else if (accessories.includes(categoryId)) {
    productCategory = "accessories";
    collection = "products";
  }

  let idCategory;
  if (categoryId === "pcparts") idCategory = "PC Parts";
  else if (categoryId === "audiovisual") idCategory = "Audio Visual";
  else if (categoryId === "graphiccards") idCategory = "Graphic Cards";
  else if (categoryId === "gamingpc") idCategory = "Gaming PC";
  else if (categoryId === "homeandoffice") idCategory = "Home & Office";
  else if (categoryId === "workstationandcreative")
    idCategory = "Workstation & Creative";
  else if (categoryId === "workstationcustom") idCategory = "Custom System";
  else if (categoryId === "soundsystem") idCategory = "Sound System";
  else if (categoryId === "webcameras") idCategory = "Web Cameras";
  else if (categoryId === "mediaplayers") idCategory = "Media Players";
  else if (categoryId === "steeringwheel") idCategory = "Steering Wheel";
  else if (categoryId === "pedalsandshifters") idCategory = "Pedals & Shifters";
  else if (categoryId === "joysticksandgamepads")
    idCategory = "Joysticks & Gamepads";
  else if (categoryId === "laptopsandnotebooks")
    idCategory = "Laptops & Notebooks";
  else if (categoryId === "poweradapters") idCategory = "Power Adapters";
  else if (categoryId === "mobilephones") idCategory = "Mobile Phones";
  else if (categoryId === "cablesandadapters") idCategory = "Cables & Adapters";
  else if (categoryId === "airpurifiers") idCategory = "Air Purifiers";
  else if (categoryId === "ledlighting") idCategory = "LED Lighting";
  else {
    idCategory = categoryId.charAt(0).toUpperCase() + id.slice(1);
  }

  useEffect(async () => {
    // product from database
    let product = await db
      .collection(collection)
      .doc(productCategory)
      .collection(categoryId)
      .doc(productId)
      .get();

    if (product.data !== undefined) setProduct(product.data());
  }, [productId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const notify = () => {
    toast("Added to Basket");
  };

  const addToBasket = () => {
    notify();

    // dispatch item into basket
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: product.id,
        title: product.title,
        image: product.image,
        price: product.price,
        rating: product.rating,
      },
    });
  };

  return (
    <div className="productID">
      <div className="productID_products_line" onClick={() => history.goBack()}>
        <div className="productID_products_category">
          <BackArrow className="productID_backArrow" />
          {"Back"}
        </div>
        <div className="pcparts_categories"></div>
      </div>

      {product.title !== "" ? (
        <div className="productID_container">
          <img className="productID_image" src={product.image} />
          <div className="productID_data">
            <div>
              <div className="productID_title">{product.title}</div>
              <div className="productID_price">
                <small>$</small> <strong>{product.price}</strong>
              </div>
            </div>
            <p className="productID_button" onClick={addToBasket}>
              Add to Basket
            </p>
          </div>
        </div>
      ) : (
        <div className="lds-container">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductID;

import React, { useState, useEffect } from "react";
import "./Payment.css";
import { useStateValue } from "./StateProvider";
import CheckoutProduct from "./CheckoutProduct";
import { Link, useHistory } from "react-router-dom";
import { getBasketTotal } from "./reducer";
import axios from "./axios";
import currencyFormatter from "currency-formatter";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { db } from "./firebase";
import { motion } from "framer-motion";

const Payment = () => {
  const [{ basket, user }, dispatch] = useStateValue();
  let history = useHistory();

  if (user === null) {
    history.push("/login");
  }
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState("");
  const [clientSecret, setClientSecret] = useState(undefined);

  const getClientSecret = async () => {
    const response = await axios({
      method: "post",
      //   Stripe expects total in a currencies subunit
      url: `/payments/create?total=${getBasketTotal(basket) * 100}`,
    });

    setClientSecret(response.data.clientSecret);
  };

  useEffect(() => {
    // generate special stripe secret which allows us to charge to customer
    getClientSecret();
  }, [basket]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (clientSecret !== undefined) {
      setProcessing(true);

      if (clientSecret !== undefined) {
        const payload = await stripe
          .confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          })
          .then(({ paymentIntent }) => {
            //payment Intent = payment confirmation

            db.collection("users")
              .doc(user?.uid)
              .collection("orders")
              .doc(paymentIntent.id)
              .set({
                basket: basket,
                amount: paymentIntent.amount,
                created: paymentIntent.created,
              });

            setSucceeded(true);
            setError(null);
            setProcessing(false);

            dispatch({
              type: "EMPTY_BASKET",
            });

            history.replace("/orders");
          });
      }
    } else {
      getClientSecret();
    }
  };

  const handleChange = (e) => {
    setDisabled(e.empty);
    setError(e.error ? e.error.message : "");
  };

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: { duration: 0.1 },
    },
    out: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 0.1 },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="payment"
    >
      <div className="payment_container">
        <h1>
          Checkout{" "}
          {
            <Link to="/Checkout" className="payment_link">
              {basket?.length} items
            </Link>
          }
        </h1>

        {/* Payment section - delivery address */}
        <div className="payment_section">
          <div className="payment_title">
            <h3>Delivery Address</h3>
          </div>
          <div className="payment_address">
            <p className="payment_address_lines"> {user?.email}</p>
            <p className="payment_address_lines">123 React Lane</p>
            <p className="payment_address_lines">Los Angles, California</p>
          </div>
        </div>

        {/* Payment section - Review Items */}
        <div className="payment_section">
          <div className="payment_title">
            <h3>Review Items and Delivery</h3>
          </div>
          <div className="payment_items">
            {basket.map((item) => (
              <CheckoutProduct
                id={item.id}
                title={item.title}
                image={item.image}
                price={item.price}
                rating={item.rating}
              />
            ))}
          </div>
        </div>

        {/* Payment section - payment method */}
        <div className="payment_section_method">
          <div className="payment_title">
            <h3>Payment Method</h3>
          </div>

          <div className="payment_details">
            {/* Stripe */}

            <form onSubmit={handleSubmit}>
              <CardElement
                className="payment_card_element"
                onChange={handleChange}
              />

              <div className="payment_priceContainer">
                <div>
                  {"Order Total: "}
                  <span className="subtotal_total">
                    {currencyFormatter.format(getBasketTotal(basket), {
                      code: "USD",
                    })}
                  </span>
                </div>

                <button disabled={processing || disabled || succeeded}>
                  <span>{processing ? <p>Processing</p> : "Buy Now"}</span>
                </button>
              </div>

              {/* Errors */}
              {error && <div className="payment_error">{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Payment;
